const ArrowIcon = () => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 37.7168C0 44.5006 5.49936 50 12.2831 50H37.7168C44.5006 50 50 44.5006 50 37.7168V12.2832C50 5.49937 44.5006 0 37.7168 0H12.2831C5.49936 0 0 5.49936 0 12.2832V37.7168ZM6.8893 22.1753H26.6979V22.1715C28.7702 22.1715 29.8045 19.6706 28.3414 18.2076L21.9366 11.8064L25.9269 7.81604L43.1069 24.9961L25.9232 42.1836L21.9328 38.1932L28.3414 31.7846C29.8045 30.3178 28.7664 27.8168 26.6979 27.8168H6.8893V22.1753Z"
    />
  </svg>
);

export default ArrowIcon;
